import React from "react"
import _ from 'lodash'
import Lightbox from 'react-images';


class Gallery extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            lightboxIsOpen: false,
            currentImage: 0
        }

        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
    }

    openLightbox(index, event) {
        event.preventDefault();
        this.setState({
            currentImage: index,
            lightboxIsOpen: true,
        });
    }

    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }

    gotoPrevious() {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }

    gotoNext() {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }

    renderPhotos(photos) {
        let imageSet = [];
        for (let photo of photos) {
            imageSet.push({
                src: photo.node.childImageSharp.fluid.src,
                srcSet: photo.node.childImageSharp.fluid.srcSet.split(',')
            })
        }
        return imageSet;
    }

    render() {
        const photo = this.renderPhotos(this.props.photos);
        return (
            <div className="sqs-block gallery-block sqs-block-gallery">
                <div className="sqs-block-content">
                    <div className="sqs-gallery-container sqs-gallery-block-grid sqs-gallery-aspect-ratio-square sqs-gallery-thumbnails-per-row-2 clear">
                        <div className="sqs-gallery sqs-gallery-design-grid" style={{marginRight: "-20px"}}>
                            {this.props.photos.map((obj, i) => {
                                let photo = obj.node.childImageSharp.fluid;
                                let thumbnail;
                                let srcSet = photo.srcSet.split(',');
                                
                                if (photo.aspectRatio < 1.5) {
                                    thumbnail = _.find(srcSet, function(o) { return o.includes('400w')});
                                } else {
                                    thumbnail = _.find(srcSet, function(o) { return o.includes('800w')});
                                }
                                
                                thumbnail = thumbnail.substring(0, thumbnail.indexOf(' '));

                                let style = {
                                    opacity: "1", 
                                    position: "relative", 
                                    objectPosition: "center",
                                    height: "422px",
                                    width: "422px"
                                }

                                let maxWidth = photo.sizes.substring(photo.sizes.indexOf(':') + 1, photo.sizes.indexOf(')') - 2);
                                if (maxWidth > 640 && photo.aspectRatio > 1.5 || photo.aspectRatio < 1) {
                                    style.objectFit = "none"
                                }

                                return (
                                    <div className="slide sqs-gallery-design-grid-slide" key={i}>
                                        <div className="margin-wrapper" style={{ marginRight: "20px", marginBottom: "20px"}}>
                                            <a
                                                href={photo.src}
                                                className="image-slide-anchor content-fill" 
                                                style={{overflow: "hidden"}}
                                                onClick={(e) => this.openLightbox(i, e)}
                                            >
                                                <img 
                                                    src={thumbnail} 
                                                    className="thumb-image loaded"
                                                    style={style}
                                                    />
                                            </a>
                                        </div>
                                    </div>
                                );
                            })}
                            <Lightbox 
                                images={photo}
                                onClose={this.closeLightbox}
                                onClickPrev={this.gotoPrevious}
                                onClickNext={this.gotoNext}
                                currentImage={this.state.currentImage}
                                isOpen={this.state.lightboxIsOpen}
                                backdropClosesModal={true}>
                            </Lightbox>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Gallery
