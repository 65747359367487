import React from "react"
import { StaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import 'bootstrap/dist/css/bootstrap.css'
import Gallery from "../components/gallery"

class VinesPage extends React.Component {
  render() {
    return <StaticQuery
      query={graphql`
    query {
        allFile(filter: {
          extension: {regex: "/(jpg)/"}
          relativeDirectory: {
            eq: "vines"
          }
        }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
  `}
      render={function (data) {
        return (<Layout>
          <SEO title="Vines" />
          <div className="Site-inner" data-controller="HeaderOverlay" id="yui_3_17_2_1_1538632099177_588">
            <header className="Header Header--bottom Header--overlay">
              <div className="Header-inner Header-inner--bottom" data-nc-group="bottom">
                <div data-nc-container="bottom-left">
                </div>
                <div data-nc-container="bottom-center">
                </div>
                <div data-nc-container="bottom-right">
                  <nav className="Header-nav Header-nav--secondary" data-nc-element="secondary-nav"
                    data-content-field="navigation">
                  </nav>
                </div>
              </div>
            </header>
            <div className="Content-outer" id="yui_3_17_2_1_1538632099177_224">
              <div className="Loader"></div>
              <main className="Main Main--page" id="yui_3_17_2_1_1538631972616_223">
                <section className="Main-content" data-content-field="main-content" id="yui_3_17_2_1_1538631972616_222">
                  <div className="sqs-layout sqs-grid-12 columns-12" data-type="page" data-updated-on="1528258901372"
                    id="page-5afdfaf6352f530714d79034">
                    <div className="row sqs-row" id="yui_3_17_2_1_1538631972616_221">
                      <div className="col-xs-12 col-md-6">
                        <div className="sqs-block html-block sqs-block-html" data-block-type="2" id="block-yui_3_17_2_1_1526597288641_3873">
                          <div className="sqs-block-content">
                            <h1>Vines</h1>
                            <h2>STEEP. DEEP. INTENSE</h2>
                            <p>Great drainage defines great wine.</p>
                            <p>Burgundy’s Cote d’Or has Kimmeridgian limestone. Chateauneuf-du-Pape has
                            rounded cobbles. Bordeaux’s finest wines are from gravel beds.
                                    </p>
                            <p>Vines with dry feet make the world’s best wines.</p>
                            <p>In the ‘80s’, Cliff and Allison Anderson searched years for the ideal
                            site; a vineyard property where deeply rooted vines produce delicious
                            wines. The quest ended at a steep massif of broken stones in the heart
                            of Oregon’s Dundee Hills; an ancient landslide of broken basalt: Truly
                            dry feet.
                                    </p>
                            <p>In 1992, Oregon wine pioneer Jack Meyers helped chisel out our vineyard.</p>
                            <p>Today, over two decades later, we organically farm vines on our steep,
                            rocky slopes.
                                    </p>
                            <p>Anderson Family crafts wine in small lots with native yeast - moved
                            entirely by gravity. Each vintage ages slowly in our cool cave
                            resulting in wines smacking of spice with layers of flavor.
                                    </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 order-first order-md-0 col-md-6" id="yui_3_17_2_1_1538631972616_220">
                        <div className="sqs-block spacer-block sqs-block-spacer" data-aspect-ratio="19.936708860759495"
                          data-block-type="21" id="block-yui_3_17_2_1_1528257081353_57470">
                          <div className="sqs-block-content sqs-intrinsic" id="yui_3_17_2_1_1538631972616_100"
                            style={{ paddingBottom: "19.9367%" }}>&nbsp;
                                  </div>
                        </div>
                        <Gallery photos={data.allFile.edges}></Gallery>
                      </div>
                    </div>
                  </div>
                </section>
              </main>
            </div>
          </div>
        </Layout>
        )
      }
      }
    />
  }
}

export default VinesPage
